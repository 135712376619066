import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { CookieStorage } from 'cookie-storage'
import dayjs from 'dayjs'

const cookieStorage = new CookieStorage({
   expires: dayjs().add(14, 'day').toDate(),
   domain: process.env.VUE_APP_APP_DOMAIN,
   path: '/'
})

export default function useUserManagerSettings(): UserManagerSettings {
   return {
      userStore: new WebStorageStateStore({ store: cookieStorage }),
      stateStore: new WebStorageStateStore({ store: cookieStorage }),
      authority: process.env.VUE_APP_IS4_API_BASE_URL!,
      client_id: 'easybudget.client',
      redirect_uri: `${process.env.VUE_APP_APP_BASE_URL}/callback`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${process.env.VUE_APP_APP_BASE_URL}/silentRenew`,
      response_type: 'code',
      scope: 'openid profile easybudget.api',
      post_logout_redirect_uri: `${process.env.VUE_APP_APP_BASE_URL}`,
      filterProtocolClaims: true
   }
}
