import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import useUserManagerSettings from './useUseManagerSettings'
import { Log } from 'oidc-client-ts'

export default class AuthService {
   settings: UserManagerSettings
   userManager: UserManager

   constructor() {
      this.settings = useUserManagerSettings()
      this.userManager = new UserManager(this.settings)
      // Set debug to true to see console logs
      const debug = false

      if (debug) {
         Log.setLevel(Log.DEBUG)
         Log.setLogger(console)
      }
   }

   async isLoggedInAsync() {
      return await this.userManager.getUser().then((user) => {
         return user != null && !user.expired
      })
   }

   async silentLogin() {
      await this.userManager.signinSilent()
   }

   async getUserAsync() {
      return await this.userManager.getUser()
   }

   async getUserIdAsync() {
      return await this.userManager.getUser().then((data) => {
         return data?.profile?.sub
      })
   }

   async loginAsync(redirectUrl: string | null = null) {
      return this.userManager.signinRedirect({
         state: redirectUrl ?? window.location.href
      })
   }

   async logoutAsync(redirectUrl: string | null = null) {
      await this.userManager.signoutRedirect({
         state: redirectUrl ?? window.location.href
      })
   }

   async getAccessTokenAsync() {
      return await this.userManager.getUser().then((data) => {
         return data?.id_token
      })
   }

   async clearStaleStateAsync() {
      await this.userManager.clearStaleState()
   }
}

const authService = new AuthService()
export const useAuthService = () => authService
