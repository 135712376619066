import AuthService, { useAuthService } from '@/auth/AuthService'
import { HttpClient as BaseHttpClient } from './BaseHttpClient'

export default class HttpClient extends BaseHttpClient {
   authService: AuthService

   constructor() {
      super()
      this.instance.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
      this.authService = useAuthService()

      this.instance.interceptors.response.use(
         (response) => {
            return response
         },
         async (error) => {
            if (error.response.status === 401) {
               return await this.authService.loginAsync()
            }

            return error.response
         }
      )

      this.instance.interceptors.request.use(
         async (config) => {
            config.headers['Authorization'] =
               'Bearer ' + (await this.authService.getAccessTokenAsync())
            return config
         },
         (error) => {
            Promise.reject(error)
         }
      )
   }
}

const httpClient = new HttpClient()
export function useHttpClient(): HttpClient {
   return httpClient
}
