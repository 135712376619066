
import { defineComponent, onMounted } from 'vue'
import { useFreshDesk } from './composables/useFreshDesk'
import useThemes from './composables/useThemes'
import { useAuthService } from './auth/AuthService'

export default defineComponent({
   name: 'App',
   setup() {
      const { hideLauncher } = useFreshDesk()
      const { init } = useThemes()
      init()
      hideLauncher()

      const authService = useAuthService()
      onMounted(async () => {
         await authService.clearStaleStateAsync()
      })

      return {}
   }
})
