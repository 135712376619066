import { useBudgetStore } from '@/composables/stores/useBudgetStore'
import dayjs, { Dayjs } from 'dayjs'

declare global {
   interface Number {
      toCurrency(): string
      toCurrencyWithSign(): string
      toPercent(): string
      toFixedRoundingError(): number
   }

   interface String {
      periodToDayJs(): Dayjs
      toPeriodToDateFormat(format: string)
      formatDate(format: string): string
      capitalizeFirstLetter()
   }

   interface Date {
      toPeriodKey(): string
      toTimeZoneDate(): Date
      removeTime(): Date
      format(format: string): string
   }

   interface Array<T> {
      moveToIndex(oldIndex, newIndex): void
   }
}

Number.prototype.toCurrency = function toCurrency() {
   const store = useBudgetStore()

   return new Intl.NumberFormat(store.currencyLocale(), {
      style: 'currency',
      currency: store.budget?.currency ?? 'PLN'
   }).format(Math.round(((this as number) + Number.EPSILON) * 100) / 100)
}

Number.prototype.toCurrencyWithSign = function toCurrencyWithSign() {
   const store = useBudgetStore()

   return new Intl.NumberFormat(store.currencyLocale(), {
      style: 'currency',
      currency: store.budget?.currency ?? 'PLN',
      signDisplay: 'always'
   }).format(this as number)
}

Number.prototype.toPercent = function toPercent() {
   return `${((this as number) * 100).toFixed(3)} %`
}

Number.prototype.toFixedRoundingError = function toFixedRoundingError() {
   return Math.round(((this as number) + Number.EPSILON) * 100) / 100
}

String.prototype.periodToDayJs = function toPeriod() {
   return dayjs(this as string, 'YYYYMM')
}

String.prototype.toPeriodToDateFormat = function toPeriodToDateFormat(
   format: string
) {
   return dayjs(this as string, 'YYYYMM').format(format)
}

String.prototype.formatDate = function formatDate(format: string) {
   return dayjs(this as string).format(format)
}

String.prototype.capitalizeFirstLetter = function capitalizeFirstLetter() {
   return this.charAt(0).toUpperCase() + this.slice(1)
}

Date.prototype.toPeriodKey = function toPeriod() {
   return dayjs(this).format('YYYYMM')
}

Date.prototype.toTimeZoneDate = function toTimeZoneDate() {
   return dayjs(this).subtract(this.getTimezoneOffset(), 'minute').toDate()
}

Date.prototype.format = function format(format: string) {
   return dayjs(this).format(format)
}

Array.prototype.moveToIndex = function moveToIndex(oldIndex, newIndex) {
   if (newIndex >= this.length) {
      let k = newIndex - this.length + 1
      while (k--) {
         this.push(undefined)
      }
   }
   this.splice(newIndex, 0, this.splice(oldIndex, 1)[0])
}

Date.prototype.removeTime = function removeTime() {
   return dayjs(this).hour(0).minute(0).second(0).millisecond(0).toDate()
}

export {}
